/*
 * @param {string} date - Fecha en formato 'YYYY-MM-DD'
 * @returns {string} - Fecha en formato 'DD de MES de YYYY'
 */
export const formatEndDate = date => {
  if (!date) return ''
  const months = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ]
  const dateArray = date.split('-')
  const day = dateArray[2].split('T')[0]
  const month = months[parseInt(dateArray[1]) - 1]
  const year = dateArray[0]
  return `${day} de ${month} de ${year}`
}

/*
 * @param {string} startDate - Fecha de inicio en formato 'YYYY-MM-DD'
 * @param {string} endDate - Fecha de fin en formato 'YYYY-MM-DD'
 * @returns {string} - Fecha en formato 'DD al DD de MES de YYYY'
 */
export const formatStartEndDate = (startDate, endDate) => {
  if (!startDate || !endDate) return ''
  const months = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ]
  const startDateArray = startDate.split('-')
  const endDateArray = endDate.split('-')
  const startDay = startDateArray[2].split('T')[0]
  const endDay = endDateArray[2].split('T')[0]
  const startMonth = months[parseInt(startDateArray[1]) - 1]
  const endMonth = months[parseInt(endDateArray[1]) - 1]
  const hasSameMonth = startMonth === endMonth
  const startYear = startDateArray[0]
  const finishYear = endDateArray[0]
  const hasSameYear = startYear === finishYear

  if (hasSameMonth) {
    return `${startDay} al ${endDay} de ${startMonth} de ${finishYear}`
  }
  if (hasSameYear) {
    return `${startDay} de ${startMonth} al ${endDay} de ${endMonth} de ${finishYear}`
  }
  return `${startDay} de ${startMonth} de ${startYear} al ${endDay} de ${endMonth} de ${finishYear}`
}
